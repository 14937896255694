import React from "react";
import theme from "theme";
import { Theme, Link, Image, Box, Section, Text, Strong, Structure } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"welcome"} />
		<Helmet>
			<title>
				Donera
			</title>
			<meta name={"description"} content={"The Plugin for the Planet. Raise money for the best climate change projects globally."} />
			<meta property={"og:image"} content={"https://uploads.quarkly.io/62958497fedca4001e327b15/images/DoneraD.png?v=2022-06-01T02:47:22.158Z"} />
			<link rel={"shortcut icon"} href={"https://uploads.quarkly.io/62958497fedca4001e327b15/images/DoneraD.png?v=2022-06-01T02:47:22.158Z"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://uploads.quarkly.io/62958497fedca4001e327b15/images/DoneraD.png?v=2022-06-01T02:47:22.158Z"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://uploads.quarkly.io/62958497fedca4001e327b15/images/DoneraD.png?v=2022-06-01T02:47:22.158Z"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://uploads.quarkly.io/62958497fedca4001e327b15/images/DoneraD.png?v=2022-06-01T02:47:22.158Z"} />
			<link rel={"apple-touch-startup-image"} href={"https://uploads.quarkly.io/62958497fedca4001e327b15/images/DoneraD.png?v=2022-06-01T02:47:22.158Z"} />
			<meta name={"msapplication-TileImage"} content={"https://uploads.quarkly.io/62958497fedca4001e327b15/images/DoneraD.png?v=2022-06-01T02:47:22.158Z"} />
		</Helmet>
		<Section background="--color-primary">
			<Override slot="SectionContent" />
			<Box
				display="flex"
				padding="12px 0"
				justify-content="space-between"
				align-items="center"
				flex-direction="row"
				md-flex-direction="column"
			>
				<Image
					src="https://uploads.quarkly.io/62958497fedca4001e327b15/images/Donera%20Logo%20-%20White.png?v=2022-06-06T15:57:09.725Z"
					display="block"
					width="200px"
					sm-width="150px"
					srcSet="https://smartuploads.quarkly.io/62958497fedca4001e327b15/images/Donera%20Logo%20-%20White.png?v=2022-06-06T15%3A57%3A09.725Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/62958497fedca4001e327b15/images/Donera%20Logo%20-%20White.png?v=2022-06-06T15%3A57%3A09.725Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/62958497fedca4001e327b15/images/Donera%20Logo%20-%20White.png?v=2022-06-06T15%3A57%3A09.725Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/62958497fedca4001e327b15/images/Donera%20Logo%20-%20White.png?v=2022-06-06T15%3A57%3A09.725Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/62958497fedca4001e327b15/images/Donera%20Logo%20-%20White.png?v=2022-06-06T15%3A57%3A09.725Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/62958497fedca4001e327b15/images/Donera%20Logo%20-%20White.png?v=2022-06-06T15%3A57%3A09.725Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/62958497fedca4001e327b15/images/Donera%20Logo%20-%20White.png?v=2022-06-06T15%3A57%3A09.725Z&quality=85&w=3200 3200w"
					sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
				/>
			</Box>
		</Section>
		<Section padding="112px 0 0px 0" md-padding="96px 0 0px 0" sm-padding="72px 0 0px 0" background="--color-primary">
			<Override slot="SectionContent" flex-direction="row" flex-wrap="wrap" />
			<Box
				display="flex"
				width="100%"
				flex-direction="column"
				justify-content="center"
				align-items="center"
				lg-width="100%"
				lg-align-items="center"
				lg-margin="0px 0px 60px 0px"
				sm-margin="0px 0px 40px 0px"
				margin="0px 0px 40px 0px"
				padding="0px 0px 0px 0px"
				sm-padding="0px 0px 0px 0px"
			>
				<Text
					margin="0px 0px 24px 0px"
					color="--light"
					font="--headline1"
					lg-text-align="center"
					sm-font="normal 700 42px/1.2 &quot;Source Sans Pro&quot;, sans-serif"
					text-align="center"
					width="80%"
					md-font="normal 700 42px/1.2 &quot;Source Sans Pro&quot;, sans-serif"
					sm-width="100%"
				>
					Thanks for Downloading!
				</Text>
				<Text
					margin="0px 0px 32px 0px"
					color="#FFFFFF"
					font="normal 300 28px/1.2 --fontFamily-sans"
					lg-text-align="center"
					text-align="center"
					width="60%"
				>
					Keep scrolling for a few tips and pointers about how to use the plugin.
				</Text>
			</Box>
			<Box
				display="flex"
				width="100%"
				justify-content="center"
				overflow-y="hidden"
				overflow-x="hidden"
				lg-width="100%"
			>
				<Image
					src="https://uploads.quarkly.io/62958497fedca4001e327b15/images/3%20copy%202.png?v=2022-06-06T17:38:04.896Z"
					max-width="100%"
					transform="translateY(10px)"
					transition="transform 0.5s ease-in-out 0s"
					hover-transform="translateY(0px)"
					srcSet="https://smartuploads.quarkly.io/62958497fedca4001e327b15/images/3%20copy%202.png?v=2022-06-06T17%3A38%3A04.896Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/62958497fedca4001e327b15/images/3%20copy%202.png?v=2022-06-06T17%3A38%3A04.896Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/62958497fedca4001e327b15/images/3%20copy%202.png?v=2022-06-06T17%3A38%3A04.896Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/62958497fedca4001e327b15/images/3%20copy%202.png?v=2022-06-06T17%3A38%3A04.896Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/62958497fedca4001e327b15/images/3%20copy%202.png?v=2022-06-06T17%3A38%3A04.896Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/62958497fedca4001e327b15/images/3%20copy%202.png?v=2022-06-06T17%3A38%3A04.896Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/62958497fedca4001e327b15/images/3%20copy%202.png?v=2022-06-06T17%3A38%3A04.896Z&quality=85&w=3200 3200w"
					sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
				/>
			</Box>
		</Section>
		<Section padding="80px 0 80px 0" sm-padding="60px 0 60px 0" background="--color-lightD1">
			<Override slot="SectionContent" flex-direction="row" flex-wrap="wrap" />
			<Box
				display="flex"
				width="50%"
				flex-direction="column"
				justify-content="center"
				align-items="flex-start"
				lg-align-items="center"
				lg-margin="0px 0px 80px 0px"
				sm-padding="0px 0px 0px 0px"
				padding="0px 0px 0px 0px"
				lg-width="100%"
				md-margin="0px 0px 50px 0px"
				sm-margin="0px 0px 30px 0px"
			>
				<Text
					margin="0px 0px 24px 0px"
					color="--dark"
					font="--headline1"
					lg-text-align="center"
					sm-font="normal 700 42px/1.2 &quot;Source Sans Pro&quot;, sans-serif"
				>
					First, make sure Donera stays active.
				</Text>
				<Text margin="0px 0px 40px 0px" color="--darkL1" font="--lead" lg-text-align="center">
					When Chrome asks you if you meant to change the page, click "Keep it" to start using Donera. You'll see this popup the next time you open a tab.
				</Text>
			</Box>
			<Box
				width="50%"
				padding="0px 0px 0px 32px"
				lg-width="100%"
				display="flex"
				lg-justify-content="center"
				lg-padding="0px 0px 0px 0px"
			>
				<Image
					src="https://uploads.quarkly.io/62958497fedca4001e327b15/images/Large%20Tile%20%281%29.png?v=2022-06-24T00:49:11.110Z"
					display="block"
					padding="10px 10px 10px 10px"
					srcSet="https://smartuploads.quarkly.io/62958497fedca4001e327b15/images/Large%20Tile%20%281%29.png?v=2022-06-24T00%3A49%3A11.110Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/62958497fedca4001e327b15/images/Large%20Tile%20%281%29.png?v=2022-06-24T00%3A49%3A11.110Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/62958497fedca4001e327b15/images/Large%20Tile%20%281%29.png?v=2022-06-24T00%3A49%3A11.110Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/62958497fedca4001e327b15/images/Large%20Tile%20%281%29.png?v=2022-06-24T00%3A49%3A11.110Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/62958497fedca4001e327b15/images/Large%20Tile%20%281%29.png?v=2022-06-24T00%3A49%3A11.110Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/62958497fedca4001e327b15/images/Large%20Tile%20%281%29.png?v=2022-06-24T00%3A49%3A11.110Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/62958497fedca4001e327b15/images/Large%20Tile%20%281%29.png?v=2022-06-24T00%3A49%3A11.110Z&quality=85&w=3200 3200w"
					sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
				/>
			</Box>
		</Section>
		<Section padding="80px 0 80px 0" sm-padding="60px 0 60px 0" sm-flex-direction="row" id="disable-adblocker">
			<Override
				slot="SectionContent"
				flex-direction="row"
				flex-wrap="wrap"
				sm-flex-direction="row"
				sm-order="0"
			/>
			<Box
				width="50%"
				lg-width="100%"
				lg-display="flex"
				lg-justify-content="center"
				padding="0px 16px 0px 0px"
				lg-padding="0px 0px 0px 0px"
			>
				<Box
					display="grid"
					grid-template-rows="repeat(9, 60px)"
					grid-template-columns="repeat(2, 1fr)"
					grid-gap="16px"
					max-width="480px"
					lg-margin="0px 0px 16px 0px"
					sm-grid-template-rows="repeat(9, 10vw)"
				>
					<Image
						src="https://uploads.quarkly.io/62958497fedca4001e327b15/images/rainforest%20portrait.webp?v=2022-06-21T16:23:18.464Z"
						border-radius="24px"
						object-fit="cover"
						width="100%"
						grid-row="1 / span 5"
						grid-column="1 / span 1"
						height="100%"
						srcSet="https://smartuploads.quarkly.io/62958497fedca4001e327b15/images/rainforest%20portrait.webp?v=2022-06-21T16%3A23%3A18.464Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/62958497fedca4001e327b15/images/rainforest%20portrait.webp?v=2022-06-21T16%3A23%3A18.464Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/62958497fedca4001e327b15/images/rainforest%20portrait.webp?v=2022-06-21T16%3A23%3A18.464Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/62958497fedca4001e327b15/images/rainforest%20portrait.webp?v=2022-06-21T16%3A23%3A18.464Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/62958497fedca4001e327b15/images/rainforest%20portrait.webp?v=2022-06-21T16%3A23%3A18.464Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/62958497fedca4001e327b15/images/rainforest%20portrait.webp?v=2022-06-21T16%3A23%3A18.464Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/62958497fedca4001e327b15/images/rainforest%20portrait.webp?v=2022-06-21T16%3A23%3A18.464Z&quality=85&w=3200 3200w"
						sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
					/>
					<Image
						src="https://uploads.quarkly.io/62958497fedca4001e327b15/images/mountainphotography.webp?v=2022-06-21T16:12:37.538Z"
						border-radius="24px"
						object-fit="cover"
						grid-column="2 / span 1"
						grid-row="2 / span 3"
						width="100%"
						height="100%"
						srcSet="https://smartuploads.quarkly.io/62958497fedca4001e327b15/images/mountainphotography.webp?v=2022-06-21T16%3A12%3A37.538Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/62958497fedca4001e327b15/images/mountainphotography.webp?v=2022-06-21T16%3A12%3A37.538Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/62958497fedca4001e327b15/images/mountainphotography.webp?v=2022-06-21T16%3A12%3A37.538Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/62958497fedca4001e327b15/images/mountainphotography.webp?v=2022-06-21T16%3A12%3A37.538Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/62958497fedca4001e327b15/images/mountainphotography.webp?v=2022-06-21T16%3A12%3A37.538Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/62958497fedca4001e327b15/images/mountainphotography.webp?v=2022-06-21T16%3A12%3A37.538Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/62958497fedca4001e327b15/images/mountainphotography.webp?v=2022-06-21T16%3A12%3A37.538Z&quality=85&w=3200 3200w"
						sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
					/>
					<Image
						src="https://uploads.quarkly.io/62958497fedca4001e327b15/images/Seacology.webp?v=2022-06-21T16:12:54.680Z"
						border-radius="24px"
						object-fit="cover"
						width="100%"
						grid-column="1 / span 1"
						grid-row="6 / span 3"
						height="100%"
						srcSet="https://smartuploads.quarkly.io/62958497fedca4001e327b15/images/Seacology.webp?v=2022-06-21T16%3A12%3A54.680Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/62958497fedca4001e327b15/images/Seacology.webp?v=2022-06-21T16%3A12%3A54.680Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/62958497fedca4001e327b15/images/Seacology.webp?v=2022-06-21T16%3A12%3A54.680Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/62958497fedca4001e327b15/images/Seacology.webp?v=2022-06-21T16%3A12%3A54.680Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/62958497fedca4001e327b15/images/Seacology.webp?v=2022-06-21T16%3A12%3A54.680Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/62958497fedca4001e327b15/images/Seacology.webp?v=2022-06-21T16%3A12%3A54.680Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/62958497fedca4001e327b15/images/Seacology.webp?v=2022-06-21T16%3A12%3A54.680Z&quality=85&w=3200 3200w"
						sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
					/>
					<Image
						src="https://uploads.quarkly.io/62958497fedca4001e327b15/images/Rainforest%20ground.webp?v=2022-06-21T16:13:16.733Z"
						border-radius="24px"
						object-fit="cover"
						width="100%"
						grid-column="2 / span 1"
						grid-row="5 / span 5"
						height="100%"
						srcSet="https://smartuploads.quarkly.io/62958497fedca4001e327b15/images/Rainforest%20ground.webp?v=2022-06-21T16%3A13%3A16.733Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/62958497fedca4001e327b15/images/Rainforest%20ground.webp?v=2022-06-21T16%3A13%3A16.733Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/62958497fedca4001e327b15/images/Rainforest%20ground.webp?v=2022-06-21T16%3A13%3A16.733Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/62958497fedca4001e327b15/images/Rainforest%20ground.webp?v=2022-06-21T16%3A13%3A16.733Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/62958497fedca4001e327b15/images/Rainforest%20ground.webp?v=2022-06-21T16%3A13%3A16.733Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/62958497fedca4001e327b15/images/Rainforest%20ground.webp?v=2022-06-21T16%3A13%3A16.733Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/62958497fedca4001e327b15/images/Rainforest%20ground.webp?v=2022-06-21T16%3A13%3A16.733Z&quality=85&w=3200 3200w"
						sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
					/>
				</Box>
			</Box>
			<Box
				display="flex"
				width="50%"
				flex-direction="column"
				justify-content="center"
				align-items="flex-start"
				lg-align-items="center"
				sm-margin="20px 0px 0px 0px"
				sm-padding="0px 0px 0px 0px"
				padding="16px 0px 16px 16px"
				lg-width="100%"
				lg-padding="24px 0px 0px 0px"
				sm-order="-1"
			>
				<Text
					margin="0px 0px 24px 0px"
					color="--dark"
					font="--headline1"
					lg-text-align="center"
					sm-font="normal 700 42px/1.2 &quot;Source Sans Pro&quot;, sans-serif"
				>
					Make sure you allow Donera on your Ad Blocker.
				</Text>
				<Text margin="0px 0px 0px 0px" color="--greyD3" font="--lead" lg-text-align="center">
					Donera is designed to be compatible with many of the plugins you already use, but double check your configurations. Check below with the most popular AdBlockers.{" "}
				</Text>
			</Box>
			<Structure>
				<Override slot="Content">
					<Override slot="cell-0">
						<Components.QuarklycommunityKitCollapse border-width="0px" animFunction="ease-out" duration=".5s" display="none">
							<Override slot="Button" text-align="center">
								AdBlock Plus
							</Override>
							<Text margin="0px 0px 0px 0px">
								Start by opening a new tab.{" "}
								<br />
								<br />
								On the browser toolbar, click the red Adblock Plus ("ABP") icon to open its menu.
								<br />
								<br />
								Click on the gear icon in the top-right corner.
								<br />
								<br />
								Go to "allowlist websites", and enter "givedonera.org." Add and save these changes.{" "}
								<br />
								<br />
								The Adblock Plus icon will turn gray indicating that it is temporarily off.
							</Text>
						</Components.QuarklycommunityKitCollapse>
						<Components.QuarklycommunityKitCollapse border-width="0px" animFunction="ease-out" duration=".5s">
							<Override slot="Button">
								AdBlock Plus
							</Override>
							<Text margin="0px 0px 0px 0px">
								Start by opening a new tab.
								<br />
								<br />
								Open the ABP plugin and select the gear icon in the top right corner.
								<br />
								<br />
								Navigate to "Allowlist websites." Type "givedonera.org", and click add.
								<br />
								<br />
								ABP should now be allowing Donera.
							</Text>
						</Components.QuarklycommunityKitCollapse>
					</Override>
					<Override slot="cell-1">
						<Components.QuarklycommunityKitCollapse border-width="0px" animFunction="ease-out" duration=".5s">
							<Override slot="Button">
								AdBlock
							</Override>
							<Text margin="0px 0px 0px 0px">
								Start by opening a new tab.
								<br />
								<br />
								On the browser toolbar, click the AdBlock hand icon to open its menu.
								<br />
								<br />
								Click the "Don't run on pages on this domain" menu option.
								<br />
								<br />
								An AdBlock settings box will open; click the{" "}
								<Strong
									overflow-wrap="normal"
									word-break="normal"
									white-space="normal"
									text-indent="0"
									text-overflow="clip"
									hyphens="manual"
									user-select="auto"
									pointer-events="auto"
								>
									Exclude
								</Strong>
								{" "}button.
								<br />
								<br />
								The AdBlock icon will turn green with a thumbs-up sign indicating that it is temporarily off.
							</Text>
						</Components.QuarklycommunityKitCollapse>
					</Override>
					<Override slot="cell-2">
						<Components.QuarklycommunityKitCollapse border-width="0px" animFunction="ease-out" duration=".5s">
							<Override slot="Button">
								Other AdBlockers
							</Override>
							<Text margin="0px 0px 0px 0px">
								Start from the Donera new tab screen.
								<br />
								<br />
								On the browser toolbar, select the icon for your ad blocking plugin.
								<br />
								<br />
								Go to setting and then select the whitelist tab.
								<br />
								<br />
								Add the URL "givedonera.org" to your whitelists. Save these changes.
							</Text>
						</Components.QuarklycommunityKitCollapse>
					</Override>
					<Override slot="Cell 0th" justify-self="auto" align-self="auto" />
				</Override>
			</Structure>
		</Section>
		<Section padding="80px 0 80px 0" sm-padding="60px 0 60px 0" background="--color-lightD1">
			<Override slot="SectionContent" flex-direction="row" flex-wrap="wrap" />
			<Box
				display="flex"
				width="50%"
				flex-direction="column"
				justify-content="center"
				align-items="flex-start"
				lg-align-items="center"
				lg-margin="0px 0px 80px 0px"
				sm-padding="0px 0px 0px 0px"
				padding="0px 0px 0px 0px"
				lg-width="100%"
				md-margin="0px 0px 50px 0px"
				sm-margin="0px 0px 30px 0px"
			>
				<Text
					margin="0px 0px 24px 0px"
					color="--dark"
					font="--headline1"
					lg-text-align="center"
					sm-font="normal 700 42px/1.2 &quot;Source Sans Pro&quot;, sans-serif"
				>
					Your first load will be the slowest. This is normal.
				</Text>
				<Text margin="0px 0px 40px 0px" color="--darkL1" font="--lead" lg-text-align="center">
					Donera will optimize load times for your browser the first time you open a new tab. This caching process makes all future sessions dramatically faster, we guarantee.
				</Text>
			</Box>
			<Box
				width="50%"
				padding="0px 0px 0px 32px"
				lg-width="100%"
				display="flex"
				lg-justify-content="center"
				lg-padding="0px 0px 0px 0px"
			>
				<Box
					display="grid"
					grid-template-rows="repeat(3, 180px)"
					grid-template-columns="repeat(2, 1fr)"
					grid-gap="32px"
					max-width="600px"
					md-grid-template-rows="repeat(3, 25vw)"
					sm-grid-gap="16px"
				>
					<Image
						src="https://uploads.quarkly.io/62958497fedca4001e327b15/images/Protest.jpg?v=2022-06-07T07:21:27.246Z"
						border-radius="24px"
						object-fit="cover"
						width="100%"
						height="100%"
						grid-column="1 / span 1"
						grid-row="1 / span 2"
						srcSet="https://smartuploads.quarkly.io/62958497fedca4001e327b15/images/Protest.jpg?v=2022-06-07T07%3A21%3A27.246Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/62958497fedca4001e327b15/images/Protest.jpg?v=2022-06-07T07%3A21%3A27.246Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/62958497fedca4001e327b15/images/Protest.jpg?v=2022-06-07T07%3A21%3A27.246Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/62958497fedca4001e327b15/images/Protest.jpg?v=2022-06-07T07%3A21%3A27.246Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/62958497fedca4001e327b15/images/Protest.jpg?v=2022-06-07T07%3A21%3A27.246Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/62958497fedca4001e327b15/images/Protest.jpg?v=2022-06-07T07%3A21%3A27.246Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/62958497fedca4001e327b15/images/Protest.jpg?v=2022-06-07T07%3A21%3A27.246Z&quality=85&w=3200 3200w"
						sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
					/>
					<Image
						src="https://uploads.quarkly.io/62958497fedca4001e327b15/images/clean.png?v=2022-06-07T07:21:42.326Z"
						border-radius="24px"
						object-fit="cover"
						width="100%"
						height="100%"
						grid-column="2 / span 1"
						grid-row="2 / span 2"
						srcSet="https://smartuploads.quarkly.io/62958497fedca4001e327b15/images/clean.png?v=2022-06-07T07%3A21%3A42.326Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/62958497fedca4001e327b15/images/clean.png?v=2022-06-07T07%3A21%3A42.326Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/62958497fedca4001e327b15/images/clean.png?v=2022-06-07T07%3A21%3A42.326Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/62958497fedca4001e327b15/images/clean.png?v=2022-06-07T07%3A21%3A42.326Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/62958497fedca4001e327b15/images/clean.png?v=2022-06-07T07%3A21%3A42.326Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/62958497fedca4001e327b15/images/clean.png?v=2022-06-07T07%3A21%3A42.326Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/62958497fedca4001e327b15/images/clean.png?v=2022-06-07T07%3A21%3A42.326Z&quality=85&w=3200 3200w"
						sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
					/>
				</Box>
			</Box>
		</Section>
		<Components.Funding background="#ffffff" />
		<Section padding="60px 0" sm-padding="40px 0" color="--dark" background="--color-lightD1">
			<Box margin="-16px -16px -16px -16px" display="flex" flex-wrap="wrap">
				<Box padding="16px 16px 16px 16px" width="33.333%" md-width="100%">
					<Box display="flex" flex-direction="column">
						<Text as="h2" font="--headline1" margin="0 0 8px 0">
							FAQs
						</Text>
						<Text as="p" font="--lead" margin="0" color="--greyD2">
							Need answers? We got’em
						</Text>
					</Box>
				</Box>
				<Box padding="16px 16px 16px 16px" width="66.66%" md-width="100%">
					<Box display="flex" flex-direction="row" flex-wrap="wrap">
						<Box
							display="flex"
							flex-direction="column"
							width="50%"
							padding="16px 16px 16px 16px"
							sm-width="100%"
						>
							<Box display="flex" flex-direction="column">
								<Text as="h3" font="--headline3" margin="12px 0">
									How does Donera make money?
								</Text>
								<Text as="p" font="--base" margin="12px 0" color="--greyD2">
									Donera uses an ad-supported model to generate revenue. We do not sell personal data, nor do we otherwise monetize your browsing behavior
								</Text>
							</Box>
						</Box>
						<Box
							display="flex"
							flex-direction="column"
							width="50%"
							padding="16px 16px 16px 16px"
							sm-width="100%"
						>
							<Box display="flex" flex-direction="column">
								<Text as="h3" font="--headline3" margin="12px 0">
									Is Donera compatible with Ecosia?
								</Text>
							</Box>
							<Text as="p" font="--base" margin="12px 0" color="--greyD2">
								Yes! Donera and Ecosia are totally compatible, and we encourage you to use both.
							</Text>
						</Box>
						<Box
							display="flex"
							flex-direction="column"
							width="50%"
							padding="16px 16px 16px 16px"
							sm-width="100%"
						>
							<Box display="flex" flex-direction="column">
								<Text as="h3" font="--headline3" margin="12px 0">
									How does Donera choose where to give funding?
								</Text>
								<Text as="p" font="--base" margin="12px 0" color="--greyD2">
									We use a rigorous vetting process to find and select the best organizations. We use a mixture of track record, referrals, and efficiency.
								</Text>
							</Box>
						</Box>
						<Box
							display="flex"
							flex-direction="column"
							width="50%"
							padding="16px 16px 16px 16px"
							sm-width="100%"
						>
							<Box display="flex" flex-direction="column">
								<Text as="h3" font="--headline3" margin="12px 0">
									Have a question that wasn't seen here?
								</Text>
								<Text as="p" font="--base" margin="12px 0" color="--greyD2">
									We're here to answer. Email hi@givedonera.com and we'll get back to you ASAP.
								</Text>
							</Box>
						</Box>
					</Box>
				</Box>
			</Box>
		</Section>
		<Structure cells-number-total="3" cells-number-group="3" sm-padding="10px 0px 50px 0px">
			<Override slot="Content" grid-template-columns="9fr 3fr" md-grid-template-columns="repeat(6, 2fr)" sm-grid-template-columns="12fr">
				<Override
					slot="Cell 0th"
					grid-column="1 / auto"
					grid-row="auto / span 2"
					md-grid-column="1 / span 6"
					md-grid-row="span"
					sm-grid-column="auto"
					sm-grid-row="span"
				/>
				<Override
					slot="Cell 1st"
					md-grid-column="1 / span 3"
					sm-grid-column="auto"
					display="block"
					align-self="end"
					justify-self="center"
					margin="0px 0px 8px 0px"
				/>
				<Override
					slot="Cell 2nd"
					md-grid-column="4 / span 3"
					sm-grid-column="auto"
					align-self="start"
					justify-self="center"
					margin="8px 0px 0px 0px"
				/>
				<Override slot="cell-0">
					<Text margin="0px 0px 0px 0px" text-align="center" font="--headline2" padding="0px 0px 20px 0px">
						Do Good.
						<br />
						Donera.
					</Text>
				</Override>
				<Override slot="cell-1">
					<Link
						href="https://chrome.google.com/webstore/detail/donera/anlcfcmefdffefahfepkjbfgkajaacfg?hl=en-US"
						color="--light"
						text-decoration-line="initial"
						font="normal 500 20px/1.5 -apple-system, system-ui, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif"
						border-radius="8px"
						margin="0px 16px 0px 0px"
						transition="background-color 0.2s ease-in-out 0s"
						hover-background="#4679e3"
						sm-margin="0px 0px 16px 0px"
						sm-text-align="center"
						sm-width="100%"
						hover-transition="background-color 0.2s ease-in-out 0s"
						target="_blank"
						sm-font="normal 500 16px/1.5 -apple-system, system-ui, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif"
						background="--color-primary"
						hover-color="--light"
						padding="12px 24px 12px 24px"
					>
						Download on Chrome
					</Link>
				</Override>
				<Override slot="cell-2">
					<Link
						href="https://docs.google.com/forms/d/1DgqYv68fR4fK1S8lGlczqsfvlJUzLezjd9mKt2S9J8w/edit"
						padding="10px 24px 10px 24px"
						color="--primary"
						text-decoration-line="initial"
						font="normal 500 20px/1.5 --fontFamily-sans"
						border-radius="8px"
						margin="0px 16px 0px 0px"
						border-width="2px"
						border-style="solid"
						border-color="--color-primary"
						sm-margin="0px 0px 0px 0px"
						sm-text-align="center"
						lg-margin="0px 0px 0px 0px"
						sm-width="100%"
						hover-border-color="--color-lightD2"
						hover-color="--lightD2"
						hover-transition="color 0.2s ease-in-out 0s,border 0.2s ease-in-out 0s"
						transition="color 0.2s ease-in-out 0s,border 0.2s ease-in-out 0s"
						hover-font="normal 500 20px/1.5 -apple-system, system-ui, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif"
						sm-font="normal 500 16px/1.5 --fontFamily-sans"
						target="_blank"
					>
						Download on Firefox
					</Link>
				</Override>
				{"                            "}
			</Override>
		</Structure>
		<Components.DoneraFooter />
		<Link
			font={"--capture"}
			font-size={"10px"}
			position={"fixed"}
			bottom={"12px"}
			right={"12px"}
			z-index={"4"}
			border-radius={"4px"}
			padding={"5px 12px 4px"}
			background-color={"--dark"}
			opacity={"0.6"}
			hover-opacity={"1"}
			color={"--light"}
			cursor={"pointer"}
			transition={"--opacityOut"}
			quarkly-title={"Badge"}
			text-decoration-line={"initial"}
			href={"https://quarkly.io/"}
			target={"_blank"}
		>
			Made on Quarkly
		</Link>
		<RawHtml>
			<style place={"endOfHead"} rawKey={"62958497fedca4001e327b13"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});